import { Avatar, Menu, ActionIcon, Text, Loader, useMantineTheme } from "@mantine/core";
import { TbLogout, TbMoon, TbSun } from "react-icons/tb";
import { useAuth0 } from "@auth0/auth0-react";
import { useTheme } from "../../context/theme.context";
import { useApi } from "../../context/api.context";

export default function UserButton() {
	const auth0 = useAuth0();
	const api = useApi();
	const theme = useMantineTheme();
	const colorScheme = useTheme();

	const colors = Object.keys(theme.colors);
	const initialsArray = auth0.user?.name?.split(" ").map(i => i[0]) ?? ["?"];
	const value = initialsArray.reduce((prev, curr) => prev + curr.charCodeAt(0), 0);
	const colorIndex = value % Object.keys(theme.colors).length;
	const initials = initialsArray.join("");

	if (auth0.isLoading) {
		return (
			<Loader/>
		);
	}

	return (
		<Menu withArrow>
			<Menu.Target>
				<Avatar
					component={ActionIcon}
					color={colors[colorIndex]}
					variant={"light"}
					radius={"xl"}
				>
					{initials}
				</Avatar>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Label>
					<Text size={16} color={"dimmed"}>{auth0.user?.name ?? "Anonymous"}</Text>
					<Text color={"dimmed"}>{auth0.user?.email ?? "Please sign in"}</Text>
					<Text color={"dimmed"}>{api.user?.roleName ?? ""}</Text>
				</Menu.Label>

				<Menu.Divider/>

				<Menu.Item
					color={colorScheme.isDark ? "yellow" : "blue"}
					icon={colorScheme.isDark ? <TbSun size={18}/> : <TbMoon size={18}/>}
					onClick={() => colorScheme.toggleColorScheme()}
				>
					{colorScheme.isDark ? "Light mode" : "Dark mode"}
				</Menu.Item>

				<Menu.Divider/>

				<Menu.Item
					icon={<TbLogout size={18}/>}
					onClick={() => auth0.logout()}
				>
					Logout
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}