import { AppShell, Paper } from "@mantine/core";
import { PropsWithChildren, useState } from "react";
import NavBar from "../navBar/navBar";
import AppHeader from "../appHeader/appHeader";

export default function AppWrapper(props: PropsWithChildren) {
	const [opened, setOpened] = useState(false);

	return (
		<AppShell
			padding={"md"}
			header={<AppHeader opened={opened} setOpened={setOpened}/>}
			navbar={<NavBar opened={opened} setOpened={setOpened}/>}
			navbarOffsetBreakpoint={"sm"}
			styles={{main: {display: "grid"}}}
		>
			<Paper
				maw={1000}
				w={"100%"}
				sx={{justifySelf: "center"}}
			>
				{props.children}
			</Paper>
		</AppShell>
	);
}