import { Alert, Button, Center, Code, Group, Modal, Stack, Text, Title } from "@mantine/core";
import { PropsWithChildren, useState } from "react";
import { TbCircleX } from "react-icons/tb";
import * as Sentry from "@sentry/nextjs";

export interface IErrorFallbackProps {
	error: Error;
	componentStack: string;
	eventId: string;

	resetError(): void;
}

function ErrorFallbackComponent(props: IErrorFallbackProps): JSX.Element {
	const [open, setOpen] = useState(false);
	return (
		<Center
			h={"100%"}
			w={"100%"}
			p={"md"}
		>
			<Alert
				color={"red"}
				icon={<TbCircleX/>}
			>
				<Group>
					<Stack spacing={0}>
						<Title
							size={"h4"}
							color={"white"}
							align={"start"}
							truncate
						>
							An error occurred
						</Title>
						<Text
							align={"start"}
							color={`red.2`}
							truncate
							size={16}
							weight={"normal"}
						>
							Please reload the page to try again.
						</Text>
					</Stack>
					<Button
						onClick={() => setOpen(true)}
						variant={"light"}
						color={"red"}
						size={"xs"}
					>
						View
					</Button>
				</Group>
			</Alert>
			<Modal
				opened={open}
				onClose={() => setOpen(false)}
				centered
				size={"auto"}
				title={
					<Text
						size={"1.25rem"}
						weight={500}
					>
						Error Details
					</Text>
				}
				overlayProps={{
					opacity: 0.5,
					blur: 4,
					zIndex: 300,
				}}
				zIndex={301}
			>
				<Stack>
					<Alert
						color={"red"}
						icon={<TbCircleX/>}
					>
						<Stack spacing={0}>
							<Title
								size={"h5"}
								color={"white"}
								align={"start"}
								truncate
							>
								Message
							</Title>
							<Text
								align={"start"}
								color={`red.2`}
								truncate
								size={16}
								weight={"normal"}
							>
								{props.error.message}
							</Text>
						</Stack>
					</Alert>
					<Code
						color={"red"}
						block
					>
						{props.error.stack}
					</Code>
					<Group position={"right"}>
						<Button
							color={"blue"}
							onClick={() => props.resetError()}
						>
							Retry
						</Button>
					</Group>
				</Stack>
			</Modal>
		</Center>
	);
}

const ErrorFallback = (props: IErrorFallbackProps) => <ErrorFallbackComponent {...props}/>;

export function ErrorBoundary(props: PropsWithChildren) {
	return (
		<Sentry.ErrorBoundary fallback={(errorData) => ErrorFallback(errorData)}>
			{props.children}
		</Sentry.ErrorBoundary>
	);
}