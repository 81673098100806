import { IPermissions } from "../../models/permissions.model";
import { ReactNode } from "react";
import { useApp } from "../../context/app.context";

export interface IPermissionCheckProps {
	permission: keyof IPermissions;
	isFalse?: boolean;
	children: ReactNode;
}

export function PermissionCheck(props: IPermissionCheckProps): JSX.Element | null {
	const app = useApp();
	
	// 
	if (!app.permissions[props.permission] === !props.isFalse) {
		return null;
	}

	return (
		<>{props.children}</>
	);
}